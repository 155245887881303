// Faq Section
{
    if ($('.acc-wrap').length) {
        $('.acc-item').each(function () {
            const accItem = $(this);
            const header = $(this).find('.acc-item__header');
            const body = $(this).find('.acc-item__body');

            header.on('click', function () {
                if (!accItem.hasClass('active')) {
                    $('.acc-item.active').removeClass('active').find('.acc-item__body').slideUp('fast');
                    accItem.toggleClass('active');
                    body.slideToggle('fast');
                } else {
                    accItem.removeClass('active');
                    body.slideUp('fast');
                }
            });

        });
        if (document.documentElement.clientWidth >= 992) {
            $(window).on('load', function () {
                $('.acc-item').eq(0).addClass('active')
                    .find('.acc-item__body').slideDown('fast');
            });
        }


        /*
        $('.acc-wrap').css({
            'min-height': $('.acc-wrap').outerHeight() + 100 + 'px'
        })
        */

    }
}