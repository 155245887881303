import {disablePageScroll, enablePageScroll} from 'scroll-lock';

$(document).on('click', 'a[href^="#"]', function (e) {
    e.preventDefault()

    if ($(this).hasClass('modal-handler')) {
        return false
    }

    const href = this.getAttribute('href')

    if (href === '#') {
        return false;
    } else {
        const section = document.querySelector(href)
        if (section) {

            if ($('.nav-side').hasClass('active')) {
                $('.nav-side').removeClass('active')
                enablePageScroll()
            }

            let offset = section.getBoundingClientRect().top + window.scrollY - 72
            const wpAdminBar = document.getElementById('wpadminbar')

            if (wpAdminBar) {
                offset -= wpAdminBar.offsetHeight
            }

            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            })


            /*
                $('html, body').animate({
                    scrollTop: offset
                }, 500);
            */


        } else {
            return false;
        }
    }

})




