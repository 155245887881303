$('.video-preview[data-yt-video-id]').on('click', function () {
    const btn = $(this)
    const modal = $('#videoModal')
    const iframe = modal.find('iframe')
    const videoId = btn.attr('data-yt-video-id');
    iframe.attr('src', `https://www.youtube.com/embed/${videoId}`)

    modal.addClass('active')
})


$('.modal .close').on('click', function () {
    const btn = $(this)
    const modal = $('#videoModal')
    const iframe = modal.find('iframe')
    modal.removeClass('active')

    setTimeout(()=>{
        iframe.attr('src', ``)
    },350)
})


$(document).on('click', '.modal', function (e) {
    const modal = $('#videoModal')
    const iframe = modal.find('iframe')
    modal.removeClass('active')

    setTimeout(()=>{
        iframe.attr('src', ``)
    },350)
})