import Swiper from 'swiper';
import {Pagination} from 'swiper/modules';

if (document.querySelector('.coupons-slider')) {
    new Swiper('.coupons-slider', {
        modules: [
            Pagination,
        ],
        loop: false,
        resistanceRatio: 0,
        grabCursor: true,
        slidesPerView: 'auto',
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
    });
}

$(function() {

    $(".toggle-coupon").click(function() {
        $(this).parent().hide();
        $(".section-coupons-slider .row > div").show();
    });

    $(".toggle-services").click(function() {
        $(this).parent().hide();
        $(".section-services-list .row > div").show();
    });


    $(".toggle-areas").click(function() {
        $(this).parent().hide();
        $(".location-list li").show();
    });

});




