import 'magnific-popup';

$('.modal-handler').magnificPopup({
    type: 'inline',
    showCloseBtn:false,
    fixedContentPos:true,
    mainClass: 'mfp-fade',
    callbacks: {
        beforeOpen: function() {

        }
    }
});


$('.btn-close').on('click', function (e) {
    e.preventDefault();
    $.magnificPopup.close(); // Close popup that is currently opened (shorthand)
})