window.$ = window.jQuery = jQuery // make jQuery from WordPress Global
"use strict";

try {
    if (typeof wpcf7 !== 'undefined' && wpcf7 !== null) {
        wpcf7.cached = 0;
    }
} catch (e) {
    console.log(e);
}

jQuery(document).ready(function ($) {
    "use strict"

    // CSS variable of Scrollbar width
    import('./components/scrollbar-width.js')

    // Anchor Links
    import('./components/anchor-scroll.js')

    // Contact Form 7
    import('./components/cf7.js')

    // Navigation
    import('./components/nav.js')

    // Gallery
    import('./components/gallery.js')

    // Form Elements
    import('./components/form-elements.js')

    // Partners Logos
    import('./components/marquee.js')

    // Faq Accordion
    import('./components/faq-accordion.js')

    // Footer
    import('./components/footer.js')

    // Video Modal
    import('./components/video-modal.js');

    // Calculator
    import('./components/calculator.js')

    // Coupons Slider
    import('./components/coupons-slider.js')

    // Popup
    import('./components/popup.js')

    // Owl Carousel
    import('./components/owl.carousel.js')

})