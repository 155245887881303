window.onload = function () {
    setTimeout(() => {
        $('.floated-left').addClass('active')
        $('.floated-right').addClass('active')
    }, 500)
}


$('.btn-remove-widgets-reviews').on('click', function (e) {
    e.preventDefault()

    $('.floated-elements .floated-left').remove()

})

function fixReviws() {
	var w = $('.section-locations .locations .location-item .widget-wrapper .saswp-r .saswp-cst .saswp-cs').width();
	$('.section-locations .locations .location-item .widget-wrapper .saswp-r .saswp-si').css('max-width', w);
	setInterval(function () {
		var w2 = $('.feedback-widget').width();
		$('.feedback-widget *').css('max-width', w2);
	}, 200);
}
fixReviws();


$( window ).on( "resize", function() {
  fixReviws();
});