import 'jquery.marquee';

if ($('.marquee').length) {
    $('.marquee').marquee({
        duration: document.documentElement.clientWidth <= 991 ? 15000 : 25000,
        gap: 0,
        delayBeforeStart: 0,
        direction: 'left',
        duplicated: true,
        startVisible: true
    });
}